import { Link } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"

import { useNotify } from "../../alert/hooks"
import { useGetSupportTickets, useUser } from "../../auth/hooks"
import {
  Chip,
  Filter,
  PageHeader,
  PageGuard,
  PageNotificationBanner,
  Table as TableBase,
} from "../../components"
import Seo from "../../components/seo"
import { TableHeading } from "../../components/Table"
import {
  getUrlSearchParams,
  removeUrlSearchParams,
} from "../../utils/navigation"

const Table = styled(TableBase)`
  margin: 20px 0;

  .table-row {
    grid-template-areas:
      "details status"
      "ticketNumber supportArea";

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      grid-template-areas: "details status ticketNumber supportArea";
    }
  }
`

const AssociateContainer = styled.div`
  grid-area: details;

  .associate-email {
    font-weight: 500;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .associate-name {
    font-size: 12px;
    line-height: 22px;
  }
`

const ChipContainer = styled.div`
  display: flex;
  grid-area: status;
  justify-self: end;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-self: start;
  }
`

const TicketStatus = {
  NEW: "NEW",
  OPEN: "OPEN",
  IN_PROGRESS: "IN_PROGRESS",
  MERCHANT_ACTION_REQUIRED: "MERCHANT_ACTION_REQUIRED",
  ESCALATED: "ESCALATED",
  SOLVED: "SOLVED",
  CLOSED: "CLOSED",
}

interface FilterOption {
  title: string
  filterRef?: Reseller.SupportTicketStatusType
  quantity?: number
}

const renderChip = (status: string, index: number) => {
  const borderColor =
    status === TicketStatus.NEW
      ? "#e1eae8"
      : status === TicketStatus.OPEN
      ? "rgba(0,169,224,0.3)"
      : status === TicketStatus.IN_PROGRESS
      ? "#ff9e0030"
      : status === TicketStatus.MERCHANT_ACTION_REQUIRED
      ? "#fa5d3730"
      : status === TicketStatus.ESCALATED
      ? "#fa5d3730"
      : status === TicketStatus.SOLVED
      ? "#01866830"
      : status === TicketStatus.CLOSED
      ? "#22222230"
      : "#000000"

  const textColor =
    status === TicketStatus.NEW
      ? "#014737"
      : status === TicketStatus.OPEN
      ? "#00a9e0"
      : status === TicketStatus.IN_PROGRESS
      ? "#ff9e00"
      : status === TicketStatus.MERCHANT_ACTION_REQUIRED
      ? "#fa5d37"
      : status === TicketStatus.ESCALATED
      ? "#fa5d37"
      : status === TicketStatus.SOLVED
      ? "#018668"
      : status === TicketStatus.CLOSED
      ? "#222222"
      : "#000000"

  const text =
    status === TicketStatus.NEW
      ? "New"
      : status === TicketStatus.OPEN
      ? "Open"
      : status === TicketStatus.IN_PROGRESS
      ? "In Progress"
      : status === TicketStatus.MERCHANT_ACTION_REQUIRED
      ? "Merchant Action Required"
      : status === TicketStatus.ESCALATED
      ? "Escalated"
      : status === TicketStatus.SOLVED
      ? "Solved"
      : status === TicketStatus.CLOSED
      ? "Closed"
      : "No Status"

  return (
    <ChipContainer key={`chip-container-${index}`}>
      <Chip
        text={text}
        backgroundColor="inherit"
        borderColor={borderColor}
        borderWidth="2px"
        textColor={textColor}
        size="medium"
        fontWeight="500"
      />
    </ChipContainer>
  )
}

const RowTextField = styled.p<{
  gridArea: string
}>`
  grid-area: ${({ gridArea }) => gridArea};
  padding-right: 5px;
  text-align: ${({ gridArea }) =>
    gridArea === "supportArea" ? "right" : "left"};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    text-align: left;
  }
`

const SupportTracking = (): React.ReactElement => {
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0)
  const { user } = useUser()
  const { data, loading, refetch, fetchMore } = useGetSupportTickets()

  const notify = useNotify()

  useEffect(() => {
    const queryParams = getUrlSearchParams(["merchant_id"])
    const merchantId = queryParams.merchant_id
    if (merchantId) {
      notify(
        "success",
        `Support ticket created for ${merchantId}`,
        "Please note that new support tickets may take up to 15min to reflect in the dashboard."
      )
      removeUrlSearchParams(["merchant_id"])
    }
  }, [])

  const tickets = data?.edges?.map(
    (ticket: { node: Reseller.SupportTicket }) => {
      return {
        id: ticket.node.salesforceId,
        userDetails: {
          name:
            ticket.node.merchant.firstName +
            " " +
            ticket.node.merchant.lastName,
          email: ticket.node.merchant.email,
        },
        salesforceStatus: ticket.node.salesforceStatus,
        ticketNumber: "#" + ticket.node.salesforceId,
        supportArea: ticket.node.category,
      }
    }
  )

  const ticketFilters: FilterOption[] = [
    {
      title: "All Tickets",
      filterRef: undefined,
      quantity: data?.count?.all,
    },
    {
      title: "New",
      filterRef: "NEW",
      quantity: data?.count?.new,
    },
    {
      title: "Open",
      filterRef: "OPEN",
      quantity: data?.count?.open,
    },
    {
      title: "In Progress",
      filterRef: "IN PROGRESS",
      quantity: data?.count?.inProgress,
    },
    {
      title: "Merchant Action Required",
      filterRef: "MERCHANT ACTION REQUIRED",
      quantity: data?.count?.merchantActionRequired,
    },
    {
      title: "Escalated",
      filterRef: "ESCALATED",
      quantity: data?.count?.escalated,
    },
    {
      title: "Solved",
      filterRef: "SOLVED",
      quantity: data?.count?.solved,
    },
    {
      title: "Closed",
      filterRef: "CLOSED",
      quantity: data?.count?.closed,
    },
  ]

  const handleFilterChange = (index: number) => {
    refetch({
      salesforceStatus: ticketFilters[index].filterRef,
    })
    setSelectedFilterIndex(index)
  }

  const handleFetchMoreTickets = useCallback(() => {
    fetchMore({
      variables: {
        after: data?.pageInfo.endCursor,
      },
    })
  }, [fetchMore, data?.pageInfo.endCursor])

  const tableHeadings: TableHeading[] = [
    {
      name: "Merchant",
      accessor: "userDetails",
      columnAllowance: "2fr",
      renderData: (
        index: number,
        details: {
          name: string
          email: string
        }
      ) => {
        return (
          <AssociateContainer key={`associate-${details.name}`}>
            <p className="associate-email">{details.email}</p>
            <p className="associate-name">{details.name}</p>
          </AssociateContainer>
        )
      },
    },
    {
      name: "Status",
      accessor: "salesforceStatus",
      columnAllowance: "2fr",
      renderData: (index: number, status: string) => renderChip(status, index),
    },
    {
      name: "Ticket No.",
      accessor: "ticketNumber",
      columnAllowance: "1fr",
      renderData: (index: number, ticketNumber: string) => (
        <RowTextField gridArea="ticketNumber" key={`ticketNumber-${index}`}>
          {ticketNumber}
        </RowTextField>
      ),
    },
    {
      name: "Support Needed",
      accessor: "supportArea",
      columnAllowance: "1fr",
      renderData: (index: number, supportArea: string) => (
        <RowTextField gridArea="supportArea" key={`supportArea-${index}`}>
          {supportArea}
        </RowTextField>
      ),
    },
  ]

  return (
    <PageGuard verifyUserProfile>
      <Seo title="Support tracking" />
      <PageHeader
        cypressTestId="support-tracking-page-header"
        title="Support tracking"
      />
      <PageNotificationBanner>
        {user?.profile?.verificationStatus !== "SUCCESSFUL" ? (
          <p>
            Your identity has not been verified yet. Please{" "}
            <Link className="highlighted" to="/onfido/">
              verify your identity
            </Link>{" "}
            to list support tickets.
          </p>
        ) : (
          <p>
            View and manage your logged support tickets (tickets older than 5
            days will be archived)
          </p>
        )}
      </PageNotificationBanner>

      {user?.profile?.verificationStatus === "SUCCESSFUL" ? (
        <>
          <Filter
            isPageFilter
            filterOptions={ticketFilters}
            onClick={handleFilterChange}
            activeIndex={selectedFilterIndex}
          />
          <Table
            headings={tableHeadings}
            rowData={tickets}
            loading={loading}
            emptyTableText="You have no support tickets to view"
            allRowsLoaded={!data?.pageInfo.hasNextPage}
            handleLoadMoreRows={handleFetchMoreTickets}
          />
        </>
      ) : null}
    </PageGuard>
  )
}

export default SupportTracking
